/* eslint-disable no-useless-escape*/
/* eslint-disable  no-useless-concat */

export const ar = {
  _SPACE_SENSE_LAUNCH_PACK_TITLE: 'تاريخ عام واحد من صور الأقمار الصناعية - حزمة المبتدئين',
  _SPACE_SENSE_LAUNCH_PACK_DESCRIPTION: 'تاريخ عام واحد من صور الأقمار الصناعية المتعلقة بمنطقة بما في ذلك فهارس NDVI و NDWI و NDRE و LAI و CHI',
  parcelsNumber: 'عدد القطع',
  areas: 'المناطق',
  areasList: 'قائمة المناطق',
  parcels: 'قطع الأرض',
  size: 'المساحة ',
  parcel: 'قطعة أرض',
  area: 'المنطقة',
  addArea: 'إضافة منطقة',
  areaInformation: 'معلومات المنطقة',
  addParcel: 'إضافة قطعة أرض',
  addSector: 'إضافة القطاع',
  stopEditing: 'توقف عن التعديل',
  editArea: 'تحيين المنطقة ',
  removeSite: 'حذف الموقع',
  removeParcel: 'حذف قطعةالأرض ',
  selectedArea: 'المنطقة المختارة',
  addSite: 'إضافة موقع',
  editParcel: 'تعديل قطعة الأرض',
  editSector: 'تعديل القطاع ',
  AgricultureArea: 'المناطق الفلاحية',
  editSite: 'تحيين بيانات قطعة الأرض',
  siteMap: 'خريطة الموقع',
  site: 'الموقع ',
  mapView: 'عرض الخريطة',
  listView: 'عرض القائمة',
  yourSiteLabel: 'مواقعك',
  goToLabel: 'ابحث عن عنوان / إحداثيات',
  doubleClickToShowAreaDetail: 'يرجى النقر مرتين على المنطقة المعروضة لتحميل التفاصيل ...',
  sousArea: 'المناطق الفرعية',
  map: 'خريطة',
  numberDeployedDevices: 'عدد الأجهزة المنتشرة',
  hectar: 'هكتار',
  navigateTo: 'انتقل إلى',
  sites: 'المواقع',
  LatitudeLabel: 'خط العرض',
  LongitudeLabel: 'خط الطول',
  siteGateway: 'مدخل',
  drippers: 'المنقطات',
  sector: 'المنطقة',
  searchPlace: 'البحث عن الأماكن',
  lines: 'خطوط',
  noParcelCreatedLabel: 'لا يوجد قطعة أرض',
  noSectorCreatedLabel: 'لا توجد قطاعات ',
  noAreaCreatedLabel: ' لا توجد مواقع ',
  onepage_areaDetail: 'المعلومات والتوصيات',
  addField: 'إضافة مدخلات جديدة',
  providerLabel: 'مزود',
  personalizationdDataTypeSource: ' مصادر نوع البيانات',
  addAreaUsingKmlFile: 'أضف منطقة باستخدام ملف KML او GeoJSON أو JSON ',
  dragDropKmlFileLabel: 'قم بسحب وإفلات ملف هنا، أو انقر لتحديد ملف KML أو  GeoJSON او  JSON ',
  wrongChoosenFile: 'تنسيق ملف خاطئ ، يرجى اختيار ملف من نوع KML أو GeoJSON أو JSON ',
  wrongFileContent: 'هذا الملف لا يطابق تنسيق المحتوى الصحيح',
  addCycleLoadingMsg: 'تحميل عملية الحساب',
  buyOneYearSateliteLabel: 'شراء سنة واحدة من تاريخ صور الأقمار الصناعية ',
  indexTypeDescription: 'سيكون لديك حق الوصول إلى 8 أنواع من الفهارس بما في ذلك NDVI و NDWI و NDRE و EVI و CHI و LAI و SAVI و RGB ',
  lunchPromotionLabel: 'إطلاق الترويج ',
  SateliteDescription: 'استخدام صور الأقمار الصناعية في الزراعة ليس شيئًا جديدًا. لقد تم استخدامه بطريقة محدودة لعقود ، في الغالب لتوفير أشياء بسيطة مثل الفهارس (NDVI ، NDWI ...). تساعدك صور القمر الصناعي في الحصول على: ',
  cropHealthIndecator: 'مؤشر صحة المحاصيل: ',
  cropHealthIndecatorDescription: 'راقب صحة محصولك عن طريق اكتشاف الضغوط الحيوية واللاأحيائية من خلال NDVI و NDWI و NDRE ',
  cropAnomalyDetector: 'كاشف شذوذ المحاصيل: ',
  cropAnomalyDetectorDescription: 'راقب صحة محصولك عن طريق اكتشاف الضغوط الحيوية واللاأحيائية من خلال NDVI و NDWI و NDRE ',
  viewDetailsLabel: 'عرض التفاصيل ',
  PayerLabel: 'دفع',
  paymentFormLabel: 'نموذج الدفع ',
  tva: 'ضريبة القيمة المضافة ',
  billingAddress: 'عنوان وصول الفواتير ',
  billingDetails: 'معلومات الفوتر ',
  department: 'الدائرة ',
  includingTaxesLabel: 'شامل الضرائب ',
  seabexOffers: 'عروض seabex ',
  seabexOffersDiscount: 'لدي كود خصم',
  applyDiscount: 'تطبيق القسيمة',
  seabexSatelliteImagesOffers: 'عروض صور القمر الصناعي مقدمة من Seabex',
  cardDetailLabel: 'معلومات البطاقة',
  dialog_newArea: 'ليس لديك مناطق محفوظة بعد. هل تريد إنشاء واحد الآن؟',
  satelliteIndex: 'مؤشرات الغطاء النباتي',
  satelliteImages: 'صور الأقمار الصناعية ',
  noSatteliteImageAvailable: 'لا توجد صور أقمار صناعية متاحة لهذه المنطقة. إذا كنت ستتمكن من الوصول إلى هذه الخدمة ، فيرجى النقر فوق الزر الموجود في بطاقة تفاصيل المنطقة. ',
  ndvi: 'مؤشر الفرق المعياري النباتي',
  ndwi: 'مؤشر الفرق المعياري للمياه',
  ndre: 'مؤشر الفرق المعياري للأشعة تحت الحمراء',
  savi: 'مؤشر النباتات المعدل بالتربة',
  chi: 'مؤشر الكلوروفيل',
  evi: 'مؤشر الغطاء النباتي المحسن',
  lai: 'مؤشر مساحة الأوراق',
  nirv: 'مؤشر قريب من الأشعة تحت الحمراء للنباتات',
  rgb: 'صورة الأقمار الصناعية',
  ndvi_description: 'باعتباره المؤشر الأكثر شيوعًا لرصد صحة المحاصيل ، يقيس NDVI مستوى اخضرار المحاصيل ومرحلة النمو. لكل بكسل في هذه الصورة الميدانية ، كلما كان البكسل أكثر اخضرارًا ، زاد الغطاء النباتي في هذه المنطقة ؛ كلما زادت احمرار البكسل ، قل الغطاء النباتي في هذه المنطقة.',
  ndwi_description: 'باعتباره المؤشر الأكثر شيوعًا لرصد الري ، يقيس NDWI حالة الإجهاد المائي للمحصول. لكل بكسل في هذه الصورة الميدانية ، كلما زاد زرقة البكسل ، زاد محتوى الماء في هذه المنطقة ؛ كلما زاد لون البكسل البرتقالي ، قل محتوى الماء في هذه المنطقة.',
  ndre_description: 'باعتباره المؤشر الأكثر شيوعًا لرصد الأسمدة ، يقيس NDRE حالة الإجهاد الغذائي للمحصول ، وخاصة النيتروجين. لكل بكسل في هذه الصورة الميدانية ، كلما كان البكسل أكثر اخضرارًا ، زاد النيتروجين في هذه المنطقة ؛ كلما زادت احمرار البكسل ، قل عدد النيتروجين في هذه المنطقة.',
  savi_description: 'على غرار NDVI ، يعد SAVI أكثر ملاءمة للمحاصيل التي تحتوي على نسبة كبيرة من الأرض العارية ، مثل النبيذ والبساتين والمحاصيل في المراحل المبكرة وما إلى ذلك. هذه المنطقة لديها كلما زادت احمرار البكسل ، قل الغطاء النباتي في هذه المنطقة.',
  chi_description: 'كمؤشر لإنتاجية المحاصيل ، يقيس ChI مستوى محتوى الكلوروفيل في أوراق النبات. لكل بكسل في هذه الصورة الميدانية ، كلما كان البكسل أكثر اخضرارًا ، زاد محتوى الكلوروفيل في هذه المنطقة ؛ كلما زادت احمرار البكسل ، قل محتوى الكلوروفيل في هذه المنطقة.',
  evi_description: 'عندما تصل بعض المحاصيل إلى مرحلة نمو متأخرة ، قد يتشبع مؤشر NDVI. يسمح لك مؤشر EVI بمواصلة مراقبة المحاصيل في مرحلة متأخرة. لكل بكسل في هذه الصورة الميدانية ، كلما كان البكسل أكثر اخضرارًا ، زادت الكتلة الحيوية في هذه المنطقة ؛ كلما زادت احمرار البكسل ، قلّت الكتلة الحيوية في هذه المنطقة.',
  lai_description: 'كمؤشر شائع الاستخدام لقياس الكتلة الحيوية ، يشير LAI إلى التباين في هندسة النبات. لكل بكسل في هذه الصورة الميدانية ، كلما كان البكسل أكثر اخضرارًا ، زادت الكتلة الحيوية في هذه المنطقة ؛ كلما زادت احمرار البكسل ، قلّت الكتلة الحيوية في هذه المنطقة.',
  nirv_description: 'مقارنة بمؤشرات الغطاء النباتي الأخرى ، فإن NIRv هو أقرب انعكاس لإنتاجية المحاصيل وإنتاجيتها. لكل بكسل في هذه الصورة الميدانية ، كلما كان البكسل أكثر خضرة ، زادت إنتاجية هذه المنطقة ؛ كلما زادت احمرار البكسل ، قلت إنتاجية هذه المنطقة.',
  rgb_description: 'عندما تتغير مؤشرات الغطاء النباتي فجأة (أي الوجود السحابي ، الحصاد) ، فإن RGB (أو الصور \'truecolor\') ، هي الأداة المثالية بالنسبة لك لإجراء فحوصات بصرية.',
  selectIndexLabel: 'يمكنك إختيار المؤشر الخاص بك',
  visibilityLabel: 'الرؤية',
  lastupdatedLabel: 'آخر تحديث في ',
  meanLabel: 'متوسط',
  historicalLabel: 'تاريخي ',
  forecastLabel: 'توقعات',
  emptyParcelLabel: 'المنطقة المحددة لا تحتوي على قطع أرض',
  emptySectorLabel: 'المنطقة المحددة لا تحتوي على قطاعات',

  // irrigation types

  irrigationAspersion: "الري بالرش",
  irrigationLocalized: "الري المحلي",
  irrigationSubmersion: "الري بالغمر",
  irrigationPivot: "الري بالأنابيب المتحركة",
  irrigationLocalizedDrip: "الري بالتنقيط",
  irrigationLocalizedMicroAspersion: "الري بالرش الدقيق",
  invoiceNumber: 'رقم الفاتورة',
  invoicePaid: 'مدفوعة',
  invoiceCurrencyAmount: 'مبلغ العملة',
  invoiceAmount: 'مبلغ',
  invoiceCurrencyTax: 'ضرائب العملة',
  invoiceDiscount: 'تخفيض',
  invoiceDeadline: 'الموعد النهائي',
  invoiceIsDraft: 'مسودة',
  currency: 'العملة',
  deployedDevices: 'الأجهزة المنتشرة',
  partnerIntegrations: 'Partner Integrations',
  deviceName: 'اسم الجهاز',
  deviceFamily: 'عائلة الجهاز',
  identifier: 'المعرف',
  addStation: 'إضافة محطة',
  stationEquipments: 'معدات ',
  deviceCategory: 'فئة الجهاز',
  deviceModel: 'طراز الجهاز',
  station: 'محطة',
  selectGateway: 'اختر بوابة',
  updateStation: 'تحديث محطة',
  port: 'المنفذ',
  selectStation: 'اختر المحطة',
  addMarker: 'إضافة علامة',
  position: 'الموقع الجغرافي',
  gateway: 'بوابة',
  monitoring: 'مراقبة',
  stations: 'المحطات',
  noModelYet: 'لا يوجد نموذج حتى الآن',
  stationName: 'اسم المحطة',
  action: 'الحدث',
  noAreaYet: 'لا توجد منطقة بعد',
  createDeployedDevice: ' قم بإنشاء جهاز النشر',
  loadingDeviceInformation: 'الحصول على معلومات الجهاز',
  lastPing: 'آخر بينج ',
  publicIp: 'بروتوكول الإنترنت العام',
  inetrnalIp: 'بروتوكول الإنترنت الداخلي',
  sdfreeSpace: 'مساحة خالية SD',
  scheduledJobsLabel: 'وظائف مجدولة',
  confirmDeviceCoordinates: 'تأكيد إحداثيات الجهاز ',
  realTimeSensorsAndActuators: 'أجهزة الاستشعار والمحركات في الوقت الحقيقي',
  selectAreaToMonitor: 'حدد المنطقة التي تريد مراقبتها',
  filterAdvice: 'استخدام المرشحات لمزيد من الدقة',
  realTimeData: 'معلومات الوقت الحقيقي -',
  calculatedValue: 'القيمة المحسوبة',
  originalValue: 'القيمة الأصلية',
  notConnectedLabel: 'غير متصل',
  connectedLabel: 'متصل',
  relay_status: 'حالة الترحيل',
  noRealTimeDataLabel: 'لا توجد بيانات فورية لهذا الموقع لأنه لا يحتوي على محطات',
  attachedOnLabel: 'تعلق على',
  onSurLabel: 'على',
  isLabel: ' ',
  caseLabel: 'السبب',
  fromDeviceLabel: 'من الجهاز ',
  dueScenarioLabel: 'بسبب سيناريو ',
  COMMAND: 'إرسال الأمر ...  ',
  UserAction: 'تعليمات المستخدم',
  AutomationPlan: 'جدول الأعمال',
  Scenario: 'سيناريو',
  possibleReasonLabel: 'السبب المحتمل: الجهاز مفصول أو متصل بشكل غير صحيح أو تالف',
  originalUnitLabel: 'الوحدة  القيمة الأصلية',
  calculatedUnitLabel: 'وحدة القيمة المحسوبة',
  onTheEquipmentLabel: 'على المعدات',
  activatedLabel: 'مفعل',
  desactivatedLabel: 'معطل',
  youAreConnectedToGatewayLabel: 'أنت متصل بالبوابة',
  noRealTimeDataToDisplayLabel: 'لا توجد بيانات في الوقت الحقيقي لعرضها',
  defaultLabel: 'بشكل افتراضي',
  cycles: 'الدورات',
  cycleLabel: 'دورة',
  day_number: 'عدد الأيام منذ الزرع',
  remainingDays: 'الأيام المتبقية',
  developmentPhase: 'مرحلة التطوير',
  'Phase de developpement': 'مرحلة التطوير',
  'Phase initiale': 'المرحلة الأولية',
  Total: 'مجموع',
  Autre: 'آخر',
  midSeasonPhase: 'مرحلة منتصف الموسم',
  suggestedValueLabe: 'هذه القيمة هي قيمة مقترحة بناءً على حساباتنا! لا تتردد في تغييره.',
  'Phase mi-saison': 'مرحلة منتصف الموسم',
  durationMidSeasonPhase: ' مدة مرحلة منتصف الموسم',
  phaseArriereSaison: 'أواخر الموسم',
  'Phase arrière-saison': 'أواخر الموسم',
  durationPhaseArriereSaison: 'مدة أواخر الموسم ',
  phase: 'المرحلة',
  currentPhaseIsLabel: 'المرحلة الحالية هي: ',
  kc: 'معامل المحاصيل',
  need_irrigation: 'بحاجة الى الري',
  endCycle: 'إنهاء دورة',
  creationCycle: 'إنشاء دورة إنتاج',
  RecommendedProductionCycle: 'استخدم دورة الإنتاج الموصى بها',
  customizeProductionCycle: 'تخصيص دورة الإنتاج الخاصة بك',
  noCycleInSite: 'لا توجد دورة إنتاج تعزى إلى هذا الموقع',
  cycleExist: 'الزراعة والمنطقة المراد إدراجها موجودة بالفعل    ',
  productionCycle: 'دورة الإنتاج',
  productionCycleCreateSuggestion: 'هل ترغب في إنشاء دورة إنتاج لهذه المنطقة الجديدة الآن ؟',
  addCycle: 'أضف دورة',
  editCycle: 'تعديل الدورة',
  cycleDetails: 'تفاصيل الدورة',
  cycleDays: 'عدد الأيام منذ بداية الدورة',
  end_cycle: 'نهاية الدورة ',
  openCycle: 'بداية الدورة',
  cycle: 'الدورة',
  cycleStatus: 'الجاري',
  finishCycle: 'إنهائه الآن',
  recommendedDate: 'التاريخ الموصى به',
  depth: '(العمق (بالمتر',
  initialStage: 'المرحلة الأولية',
  durationInitialStage: 'مدة المرحلة الأولية',
  rootingDepth: 'عمق الجذور ',
  sensibilite: 'حساسية',
  durationDevelopmentPhase: 'مدة مرحلة التطوير',
  addGrowthDuration: 'إضافة مدة النمو',
  editGrowthDuration: '  تعديل مدة النمو',
  growthDurationLabel: 'مدة النمو',
  yearsSinceImplimantation: 'عدد السنوات منذ الزرع',
  monthSinceImplimantation: 'عدد الأشهر منذ الزرع',
  recalculateLabel: 'إعادة الحساب',
  cycleInCalculation: 'الحساب جاري',
  cycle_text: 'مراحل التطور المختلفة',
  cycleEndedOn: 'انتهى في',
  irrigationDaysNumber: 'عدد أيام الري',
  gettingCycleInformation: 'الحصول على معلومات الدورة',
  first_part: 'أول 10 أيام  ',
  middle_part: 'العشرة أيام الثانية  ',
  end_part: 'آخر 10 أيام  ',
  evolutionCoefficientLabel: 'معامل التطور الزراعي - المصدر (FAO)',
  currentPeriod: 'الفترة الحالية ',
  implantationDateLabel: 'تاريخ الزرع ',
  ofLabel: 'من',
  ageLabel: 'العمر',
  nocycleFoundedInSelectedArea: 'المنطقة المحددة لا تحتوي على تشغيل إنتاج ، هل تريد إنشاء؟ ',
  cropCoefficientDescription: 'معامل المحصول (kc) هو النسبة بين تبخر المحصول (ETc) والتبخر المحتمل (ET0) ، وهو يدمج تأثيرات الخصائص الأساسية الأربعة التي تميز المحصول عن المحصول المرجعي وهي: ارتفاع المحصول ، مقاومة سطح التربة والغطاء النباتي ، البياض ، تبخر التربة (Allen et al ، 1998). " ',
  numberOfDaysPerIrrigation: 'عدد الأيام لكل جولة ري',
  specifyDateLabel: 'حدد التاريخ',
  currentPhaseLabel: 'المرحلة الفينولوجية الحالية',
  newPhaseLabel: 'مرحلة جديدة',
  validateBtnLabel: 'للتحقق من صحة',
  changePhaseLabel: 'تغير المرحلة',
  thePhaseLabel: 'المرحلة',
  endOnLabel: 'اكتمل في ',
  waterRequirementLabel: 'متطلبات المياه وتوازن المياه',
  evapotranspiration: 'التبخر المحتمل ETP ',
  totalPrecipitation: 'هطول الأمطار الكلي ',
  totalPrecipitationForecasted: 'توقعات هطول الأمطار لهذا اليوم',
  waterInSoil: 'كمية المياه  في التربة  ',
  availableWaterInSoil: 'كمية المياه  في التربة  ',
  actualEvapotranspiration: 'التبخر الفعلي',
  todayWaterNeed: 'إحتياج المحاصيل اليوم للمياه',
  calculationParam: 'ضبط الحسابات',
  waterNeedHistory: 'تاريخ إحتياج المياه',
  useful_Reserve: 'احتياطي قابل للاستخدام',
  easily_useful_Reserve: 'احتياطي قابل للاستخدام بسهولة',
  survivalReserve: 'احتياطي البقاء على قيد الحياة',
  adjustedSurvivalReserve: 'احتياطي البقاء على قيد الحياة المعدل',
  eau_utile: 'مياه صالحة للاستعمال',
  irrigationQantity: 'كمية الري  ',
  waterNeedNextDays: 'الاحتياجات المائية لأيام جولة مائية كاملة',
  fillRate: 'معدل ملء التربة  ',
  metreJour: 'مم ',
  precipitationUnit: 'لتر/متر مربع',
  hectarMcube: 'متر مكعب / هكتار',
  drainageLabel: 'المياه المصرفة',
  waterQuantity: 'كمية الماء',
  waterNeedRecalculatedProcessing: 'جارٍ إعادة حساب متطلبات المياه',
  waterNeedRecalculated: 'تمت إعادة حساب متطلبات المياه بنجاح',
  adjustedIrrigationVolume: 'الري المقترح',
  adjustedSoilWaterVolume: 'احتياطي المياه المعدل',
  howManyDaysLabel: 'كل كم يوما',
  simplifiedViewLabel: 'عرض مبسط',
  recommendationsViewLabel: 'عرض مع التوصيات',
  totalPrecipitationUnitLabel: 'إجمالي الهطول (مم)',
  totalIrrigationsPerformedLabel: 'إجمالي الري المنفذ (مم)',
  totalRecommendedIrrigations: 'إجمالي الري الموصى به (مم)',
  dataDetailsLabel: 'تفاصيل البيانات',
  waterNeedOnDemand: 'متطلبات المياه عند الطلب ',
  drawZone: 'ارسم منطقة',

  apply_changes_for_other_areas: "هل ترغب في تطبيق التغييرات على قطع الأخرى؟",
  automationPlan: 'خطة الري',
  automationPlans: 'خطط التشغيل الآلي',
  AddAutomationPlan: 'إضافة تشغيل الآلي',
  editAutomationPlan: 'تعديل التشغيل الالي',
  tasksLabel: 'المهام',
  addIrrigationPlan: 'إضافة خطة الري',
  selectedMonths: 'الشهور المختارة',
  selectedMonthDays: 'اختيار أيام الشهر',
  selectedWeekDays: 'اختيار أيام الأسبوع',
  selectActuator: 'إختيار المنفذ ',
  onLabel: 'تشغيل  ',
  offLabel: 'إيقاف',
  scenarioLabel: 'قواعد التشغيل الآلي',
  chapriority: 'أولوية',
  changePriority: 'تغيير الأولوية',
  operatorLabel: 'عامل رياضيات',
  conditions: 'ضبط الشروط',
  scEquation: 'معادلة',
  sensor: 'المستشعر',
  sensorType: 'نوع المستشعر',
  warningCondition: 'يجب توفير شرط واحد على الأقل',
  warningAction: 'يجب تقديم إجراء واحد على الأقل',
  actionType: 'نوع الإجراء',
  priority: 'أولية',
  conditionsList: 'قائمة الشروط ',
  deviceID: 'معرف الجهاز',
  shared_device: 'جهاز مشترك',
  related_areas: 'المواقع المعنية',
  pin: 'الموقع',
  operator: 'عامل رياضيات <>=',
  value: 'القيمة',
  equation: 'معادلة',
  astationOrAutomation: 'Station/AutomationPlan',
  delay: 'الوقت',
  delayInMinute: 'الوقت بالدقائق',
  selectStatus: 'وضع البداية',
  Logs: 'السجلات',
  multiLogs: 'سجلات متعددة ',
  dataLogs: 'سجلات البيانات',
  findLogs: 'البحث عن سجلات',
  choiceBySite: 'الاختيار حسب الموقع',
  choiceByDeployedDevice: 'الاختيار حسب الجهاز المنتشر',
  downloadLabel: 'تحميل ',
  inputSourceOrigin: 'أصل هذا الإدخال هو ملف',
  loadingFilelogs: 'تنزيل الملف قيد التقدم ...',
  intervalUnitLabel: 'وحدة الفاصل',
  intervalNumberLabel: 'رقم الفاصل',
  addNewDataTypeLabel: 'أضف نوع بيانات جديد',
  confirmSelectionLabel: 'قم بتأكيد الاختيار',
  saveSelectionLabel: 'Save sélection',
  autoScaleLabel: 'مقياس تلقائي',
  findEvents: 'البحث عن الأحداث',
  eventsList: 'قائمة الأحداث',
  Events: 'أحداث',
  network: 'شبكتي',
  reason: 'السبب',
  assistedMode: 'الوضع بمساعدة',
  automaticMode: 'الوضع التلقائي',
  detailsLabel: 'التفاصيل',
  connect: 'ارتبط',
  duration: 'المدة الزمنية',
  AssistedAction: 'ساعد العمل',
  modeLabel: 'الوضع ',
  ofTheDeviceLabel: 'من الجهاز',
  youHaveActivatedLabel: 'لقد قمت بالتنشيط',
  youHaveDesactivatedLabel: 'لقد قمت بإلغاء التنشيط',
  atTimeLabel: 'الى',
  errorOccuredOnLabel: 'حدث خطأ في',
  youAreConnectedLabel: 'انت متصل',
  sentACommandLabel: 'لقد أرسلت أمرًا على ',
  youOpenedLabel: 'لقد قمت بفتح',
  youClosedLabel: 'لقد قمت بغلق',
  onTheDeviceLabel: 'على الجهاز',
  irrigationMangement: 'إدارة الري',
  irrigationDate: 'تاريخ الري',
  addIrrigation: 'أضف الري',
  editIrrigation: 'تعديل الري',
  findIrrigation: 'ابحث عن الري',
  irrigationType: 'نوع الري ',
  addIrrigationType: 'إضافة نوع الري',
  editIrrigationType: 'تعديل نوع الري',
  irrigationFamily: 'عائلة الري',
  addIrrigationFamily: 'إضافة عائلة الري',
  editIrrigationFamily: 'تعديل عائلة الري',
  irrigationMode: 'وضع الري',
  userIrrigationManagement: 'إدارة أنواع الري',
  waterVolume: 'الري',
  irrigationVolume: 'كمية الري',
  waterVolumeLabel: 'حجم المياه ',
  isoUnit: 'وحدة ايزو',
  efficiency: 'كفاءة',
  automatic: 'تلقائي',
  manuel: 'يدوي',
  gettingIrrigationInformation: 'الحصول على معلومات الري',
  addMultipleIrrigationLabel: 'إضافة ري متعدد',
  multipleIrrigationLabel: 'ري متعدد',
  rainfallManagement: 'إدارة هطول الأمطار ',
  rainfallList: 'قائمة هطول الأمطار',
  currentPluvio: 'هطول الأمطار الحالي',
  correctedRainfall: 'هطول الأمطار المصحح',
  subscriptions: 'الاشتراكات',
  subscribe: 'الإشتراك',
  smsPrice: 'سعر الرسائل النصية القصيرة',
  emailPrice: 'سعر خدمة البريد الإلكتروني',
  appPrice: 'سعر خدمة التطبيقة',
  price: 'السعر',
  total: 'المجموع',
  apiLabel: 'واجهة برمجة تطبيقات ',
  alert: 'تحذير',
  advice: 'النصيحة',
  sms: 'رسالة قصيرة',
  application: 'تطبيق',
  addSubscription: 'إضافة اشتراك',
  editSubscription: 'تعديل الاشتراك',
  SubscriptionType: 'نوع الاشتراك',
  disableEdit: 'لا يمكنك تعديل ',
  showAllGroup: 'عرض جميع المجموعات',
  showMyGroup: 'عرض مجموعاتي',
  groupAdded: 'تمت إضافة المجموعة بنجاح',
  addGroup: 'إضافة مجموعة',
  groupLabel: 'المجموعة',
  leftGroup: 'تركت المجموعة',
  groupeManagment: 'إدارة المجموعات',
  editGroup: 'تعديل مجموعة',
  leaveGroup: 'مغادرة المجموعة',
  disableDelete: 'لا يمكنك حذف ',
  subscribeSucces: 'أنت مشترك في المجموعة',
  alreadySubscribe: 'لقد اشتركت بالفعل في المجموعة',
  unfollow: 'الغاء المتابعة',
  followers: 'متابعون',
  nbFollowers: 'عدد المتابعين',
  nbFollowersSeabex: 'عدد أعضاء SEABEX',
  newFollower: 'تابع جديد',
  fullName: 'الاسم الكامل',
  fullNameOnCard: 'الاسم الكامل على البطاقة',
  agents: 'الوكلاء',
  newAgent: 'وكيل جديد',
  agent: 'وكيل',
  sentByAgent: 'أرسلت بواسطةالوكيل',
  SentNotifNumber: 'عدد الإخطارات المرسلة',
  maximumHeat: 'درجة الحرارة القصوى ',
  minimumHeat: 'درجة الحرارة الدنيا ',
  temperature: 'درجة الحرارة',
  humidity: 'معدل الرطوبة',
  temperatureDewPointWeekLabel: 'اتجاهات درجة الحرارة / نقطة الندى لليوم والـ 48 ساعة القادمة ',
  temperatureAverage: ' متوسط ​​درجة الحرارة',
  rainPrecipitation: 'هطول الأمطار',
  snowPrecipitation: 'تساقط الثلوج',
  uvIndex: 'مؤشر الأشعة فوق البنفسجية\u200e ',
  cloudCover: 'كثافة الغيوم',
  dewPoint: 'درجة  حرارة تكون الندى',
  windSpeed: 'سرعة الرياح',
  windDirection: 'اتجاه الرياح',
  windUnit: 'كم/ساعة',
  windSpeedUnit: 'م / ث',
  sunset: 'الشروق و الغروب',
  veryHighLabel: 'عالي جدا',
  windStatus: 'حالة الرياح',
  highLabel: 'عالي',
  extremeLabel: 'شديد',
  pressureLabel: 'الضغط الجوي',
  nextWeekWeather: 'طقس الأيام القادمة',
  weatherStations: 'محطات الطقس',
  Evolution24H: 'توقعات الطقس اليوم على مدار 24 ساعة',
  WeatherToday: 'الطقس اليوم',
  temperatureApparent: 'درجة الحرارة المحسوسة',
  humidite_au_point_fletrissement: 'نقطة الذبول',
  bas_du_rfu: 'الحد الأدنى للاحتياطي القابل للاستخدام بسهولة',
  seuil_declenchement: 'نقطة تشغيل الراي',
  moderateLabel: 'معتدل',
  lowLabel: 'منخفض',
  timeZone: 'منطقة زمنية',
  weatherTrends: 'اتجاهات الطقس',
  SoilElecConductivity: 'الموصلية الكهربائية للتربة',
  SoilTemperature: 'درجة حرارة التربة',
  SoilWaterContent: 'محتوى الماء الحجمي للتربة',
  chooseMySoilType: 'اختر نوع التربة الخاص بي',
  createMyCustomSoilType: 'إنشاء نسيج التربة الخاص بي كل أفق على حدة',
  customSoilStructure: 'نسيج التربة المخصص حسب الأفق',
  addNewSoilHorizon: 'أضف أفق تربة جديد',
  soilStructure: 'هيكل التربة في المنطقة المختارة',
  addSoilType: 'إضافة نوع التربة',
  editSoilType: 'تغيير نوع التربة',
  solTexture: 'نسيج التربة',
  addSoilTexture: 'إضافة نسيج التربة',
  editSoilTexture: 'تغيير نسيج التربة',
  soilSensibilite: 'حساسية التربة',
  recommendedSoilType: 'استخدم نوع التربة المقدر',
  soilType: 'نوع التربة',
  soilFamily: 'عائلة التربة ',
  userSoilManagement: 'إدارة التربة',
  humidite_capacite_au_champ: 'طاقة الحقل',
  salinity: 'الملوحة',
  densite_apparente: 'الكثافة الظاهرية',
  deleteCulture: 'حدف الزراعة',
  cultureFamily: 'عائلة الزراعة',
  addCultureFamily: 'إضافة عائلة الزراعة',
  editCultureFamily: 'تعديل عائلة الزراعة',
  deleteCultureFamily: 'حذف عائلة التربة ',
  addCulture: 'إضافة زراعة',
  editCulture: 'تعديل الزراعة',
  Culture_Name: 'إسم الزراعة  ',
  culture: 'زراعة',
  userCultureManagement: 'إدارة المحاصيل',
  phaseNameLabel: 'اسم المرحلة',
  phaseDurationLabel: 'مدة المرحلة ',
  developmentStagesLabel: 'الرجاء إدخال مراحل تطور النبات ',
  DegreeDayOfGrowthLabel: 'درجة النمو اليومي',
  cultureDetailLabel: 'تفاصيل الزراعة',
  length: 'المدة الزمنية',
  name: 'المرحلة',
  degree: 'الدرجة ',
  rootDepth: 'العمق',
  userPreferencesLabel: 'خيارات المستخدم',
  fileSize: 'حجم الملف',
  fileName: 'اسم الملف',
  bytesLabel: 'بايت',
  dragDropFileLabel: 'قم بسحب "وإسقاط" بعض الملفات هنا ، أو انقر لتحديد الملفات',
  correspondenceTableLabel: 'جدول المراسلات',
  sourcesManagement: 'إدارة المصادر',
  sourceLabel: 'المصادر',
  measureLabel: 'التدابير',
  colmunsLabel: 'الأعمدة',
  fileManagment: 'إدارة الملفات',
  configurationLabel: 'ترتيب',
  fileList: 'قائمة الملفات',
  dataSource: 'مصدر البيانات',
  fileSended: 'تم إرسال الملف بنجاح',
  waitWhileFileIsSending: 'جارٍ تحميل ملف إرسال ...',
  wrongChoosenXlsxFile: 'تنسيق ملف خاطئ ، يرجى اختيار ملف من نوع XLSX',
  publicationsLabel: 'المنشورات',
  homePageLabel: 'ترحيب',
  AuthorLabel: 'مؤلف',
  subTitle: 'العنوان الفرعي',
  publicationManagment: 'إدارة النشر',
  publicationLabel: 'المنشور',
  worldWideLabel: 'في جميع أنحاء العالم',
  equipmentList: 'نوع قائمة المعدات',
  addEquipmentType: 'إضافة نوع المعدات',
  addEquipment: 'إضافة المعدات',
  editEquipmentType: 'تعديل نوع المعدات',
  editEquipment: 'تعديل المعدات',
  equipmentLabel: 'المعدات',
  noAttachedEquipment: 'لا توجد معدات مرفقة',
  noAttachedDataType: ' لا يوجد نوع بيانات مرفقة',
  equipmentType: 'نوع الآداة',
  userEquipmentManagment: 'إدارة المعدات',
  reference: 'المرجع',
  equipmentTypeOfFamily: 'أنواع معدات أسرة',
  financialManagment: 'الإدارة المالية  ',
  financialCategorie: 'الفئات المالية',
  accountingManagement: 'إدارة الحسابات',
  invoiceManagement: 'إدارة الفواتير',
  customerManagement: 'ادارة الزبائن',
  productManagement: 'ادارة المنتجات',
  voucherManagement: 'ادارة القسيمة',
  financialCategoryLabel: 'الفئة المالية',
  userName: 'اسم المستخدم',
  financialType: 'النوع المالي',
  financialCategoryParent: 'الأصل الفئة المالية',
  expensesRevenue: 'المصاريف / الايرادات ',
  myFinancialCategorieLabel: 'فئاتي المالية ',
  activitiesManagement: 'إدارة الأنشطة',
  activitiesList: 'قائمة الأنشطة',
  activityLabel: 'نشاط',
  activityType: 'نوع النشاط',
  pickColor: 'اختر لونا',
  activityTypeByMe: 'نوع أنشطتي',
  activities: 'الأنشطة',
  addActivity: 'إضافة نشاط',
  addActivityType: 'إضافة فئة',
  culturalActivities: 'الأنشطة زراعية',
  agriculturalActivities: 'الأنشطة زراعية',
  January: 'جانفي',
  February: 'فيفري',
  March: 'مارس',
  April: 'أفريل',
  May: 'ماي',
  June: 'جوان',
  July: 'جويلية',
  August: 'أوت',
  September: 'سبتمبر',
  October: 'أكتوبر',
  November: 'نوفمبر',
  December: 'ديسمبر',
  'Every Month Day': 'كل أيام الشهر',
  'Every Day': 'كل يوم',
  Monday: 'الإثنين',
  Tuesday: 'الثلاثاء',
  Wednesday: 'الأربعاء',
  Thursday: 'الخميس',
  Friday: 'الجمعة',
  Saturday: 'السبت',
  Sunday: 'الأحد',
  Tomorrow: 'غدا',
  today: 'اليوم',
  Yesterday: 'في الامس',
  now: 'الآن',
  dayLabel: 'يوم',
  MonthLabel: 'شهر',
  'Every Month': 'كل شهر',
  timeLabel: 'زمن',
  startTime: 'وقت البدء',
  endTime: 'وقت النهاية',
  from: 'من',
  to: 'إلى',
  created_at: 'أنشئت في',
  date: 'التاريخ',
  startDate: 'تاريخ البدء  ',
  endDate: 'تاريخ الانتهاء',
  captureTime: 'وقت الالتقاط',
  noRecordToDisplay: 'لا يوجد سجل للعرض',
  rowsLabel: 'صفوف',
  actions: 'الإجراءت',
  nextpage: 'Next Page',
  lastpage: 'آخر صفحة',
  previouspage: 'الصفحة السابقة',
  firstpage: 'الصفحة الأولى',
  search: 'بحث',
  noDataAvailable: 'لا توجد بيانات متاحة',
  next: 'التالي',
  previous: 'السابق',
  refreshData: 'تحديث البيانات',
  titleLabel: 'العنوان',
  loading: 'جاري التحميل',
  valueLabel: 'تقدير',

  rainfallEditReminder: "عند الانتهاء من تعديل كمية الأمطار ، لا تنسَ حفظ التغييرات الخاصة بك.",
  saveChanges: "حفظ التغييرات",
  confirmCancelMessage: "يرجى تأكيد أو إلغاء التغيير قبل المتابعة!",
  subArea: "قطعة فرعية",
  referenceArea: "القطعة المرجعية",

  //snackbar msg 
  deleteMsg: 'حذف بنجاح',
  accessGranted: 'لقد تم منح الوصول!',
  confirmationLabel: 'هل أنت متأكد من أنك تريد',
  confirmationLabelEndCycle: 'هل أنت متأكد أنك تريد إغلاق الدورة في التاريخ المحدد',
  emailCheckAccount: 'يرجى التحقق من بريدك الالكتروني للتحقق من حسابك',
  emailCheckPassword: 'يرجى التحقق من بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك',
  emailCheckPasswordChanged: 'تم تغيير كلمة المرور بنجاح ، يرجى التحقق من حسابك',
  checkAccessCode: 'يرجى التحقق من رموز الوصول الخاصة بك',
  errorOccurred: 'لقد حدث خطأ ',
  updateSucc: 'تم التحديث بنجاح',
  addedSucc: 'اضيف بنجاح',
  registerDone: 'لقد تم التسجيل ',
  connectionSuccessful: 'تم الاتصال بنجاح',
  connectionFailed: 'فشل الاتصال',
  enabled: 'يعمل',
  disabled: 'لا يعمل',
  accepted: 'قبلت',
  proposed: 'المقترحة',
  theLabel: 'ال',
  successfullyConnectedOn: 'تم الاتصال بنجاح',
  at: 'في',
  successfullyExecutionOn: 'تم التنفيذ بنجاح على',
  automationPlanExecutionOn: 'تنفيذ خطة الأتمتة على',
  automationPlanExecutionFailedOn: 'فشل تنفيذ خطة الأتمتة في',
  addNewUser: 'إضافة مستخدم جديد',
  EditUser: 'تعديل مستخدم جديد',
  mobile: 'رقم الهاتف',
  address: 'العنوان',
  diploma: 'الشهادة',
  speciality: 'الإختصاص',
  experience: 'الخبرة',
  region: 'المنطقة',
  skills: 'المهارات',
  agree: 'اتفق',
  disagree: 'لا أوافق',
  invitationVoucher: 'قسيمة شراء أو خصم',
  iHaveAVoucherDiscount: 'لدي كود خصم',
  applyVoucher: 'تطبيق',
  invitationVoucherWarning: 'التسجيل في منصة Seabex مفتوح ، في المعاينة ، للعملاء الذين قدموا طلباتهم إلى فريق Seabex عبر نموذج الدعوة. يجب أن يكون لديك رمز دعوة لتتمكن من التسجيل. تأكد من حصولك على هذا الرمز قبل التسجيل.',
  firstname: 'الاسم',
  lastname: 'اللقب',
  email: 'البريد الإلكتروني',
  password: 'كلمة السر',
  c_password: 'تأكيد كلمة السر',
  memberSince: 'عضو منذ',
  ville: 'مدينة',
  enterPhoneNumber: 'يرجى إدخال رقم هاتفك',
  getContactedBySeabex: 'إذا كنت ترغب في أن يتم الاتصال بك من طرف شركة Seabex لمتابعة حسابك ، يرجى إدخال رقم هاتفك',
  requiredPhoneNumber: 'رقم الهاتف مطلوب',
  invalidPhoneNumber: 'رقم الهاتف غير صحيح',
  clientSeabex: 'عضو SEABEX',
  username: 'اسم المستخدم',
  userList: 'قائمة الأعضاء',
  invoiceList: 'قائمة الفواتير',
  voucherList: 'قائمة القسائم',
  addNewVoucher: 'إضافة قسيمة جديدة',
  EditVoucher: 'تحرير القسيمة',
  clientList: 'قائمة العملاء',
  productList: 'قائمة المنتجات',
  organizationList: 'قائمة المؤسسات',
  forgetPassword: 'نسيت كلمة مرورك؟ انقر هنا.',
  checkYourEmail: 'تحقق من بريدك الالكتروني',
  resetPassword: 'إعادة تعيين كلمة المرور',
  newpassword: 'كلمة السر الجديدة',
  placeToSearch: 'أدخل مكانًا للبحث عنه',
  userManagement: 'إدارة المستخدم',
  partnerManagement: 'إدارة الشركاء',
  organizationManagement: 'إدارة المؤسسات',
  areaManagement: 'إدارة المناطق',
  termsConditions: 'الأحكام والشروط',
  youCanCreateOne: 'يمكنك إنشاء واحدة هنا',
  privacyPolicy: 'سياسة خاصة',
  activeLabel: 'مفعل',
  active: 'فعال',
  inactiveLabel: 'معطل',
  notConnectedYet: 'غير متصل بعد',
  restoreLabel: 'استرجع',
  restoreDescription: 'ستحذف هذه العملية نهائيًا: بيانات المنطقة المحددة: ',
  restoredItem: 'البيانات المجمعة ، الطقس ، الري ، الدورة ، الأجهزة المنتشرة ',
  noAreaToDisplayLabel: 'المستخدم المحدد ليس لديه مناطق لعرضها ',
  securePasswordConditions: 'الحد الأدنى 10 أحرف أبجدية رقمية مع، ويفضل، حرف واحد على الأقل، وعدد ورمزا',
  userType: 'أنت (يرجى تحديد الخيار الذي ينطبق)',
  errorInSignUp: 'حدث خطأ أثناء عملية التسجيل الخاصة بك ، يرجى التحقق من المعلومات التي تم إدخالها.',
  successfulSingup: 'نشكرك على التسجيل في منصة Seabex ، لقد أرسلنا إليك بريدًا إلكترونيًا للتحقق. الرجاء الضغط على الرابط لتفعيل حسابك.',
  registrationSuccLabel: 'تم التسجيل بنجاح ',
  registrationErrorLabel: 'خطأ في التسجيل',
  accountExistLabel: 'الحساب موجود بالفعل! ',
  invalidInvitationVoucher: 'رمز الدعوة غير صالح!',
  _MOBILE_ALREADY_EXISTS_: 'رقم الهاتف هذا مرتبط بحساب آخر!',
  dontHaveAnAccount: 'إذا كنت لا تملك حساب',
  signin: 'تسجيل الدخول',
  alreadyRegistered: 'هل قمت بالتسجيل سابقاً ؟ تسجيل الدخول هنا',
  signup: 'التسجيل',
  logout: 'الخروج',
  loginInvalidLabel: 'البريد الإلكتروني أو كلمة المرور غير صحيحة حاول مرة أخرى',
  mailInvalid: 'البريد الإلكتروني غير صحيح',
  formFillText: 'يرجى ملء هذا النموذج للتسجيل في المنصة:',
  formFillTextAddUser: 'يرجى ملء هذا النموذج لتسجيل مستخدم جديد على المنصة:',
  acceptTerms: 'للمتابعة ، يرجى النقر فوق مربع الاختيار لتأكيد موافقتك على <a href=\'https://seabex.com/conditions-generales-dutilisation/\' target=\'_blank\'> شروط وأحكام الاستخدام </a> و <a href=\'https://seabex.com/politique-de-confidentialite/\' target=\'_blank\'>سياسة الخصوصية</a>',
  Farmer: 'مزارع',
  Expert: 'خبير',
  Super: 'مشرف',
  Institution: 'مؤسسة',
  searchCountry: 'البحث عن البلد',
  specifications: 'مواصفات',
  searchGovernorate: 'البحث عن المحافظة',
  governates: 'محافظات',
  delegations: 'المعتمدية',
  city: 'المدينة',
  country: 'البلد',
  government: 'حكومي',
  confirmBtn: 'تأكيد',
  cancelBtn: 'إلغاء',
  add: 'إضافة',
  'delete': 'حذف',
  editLabel: 'تعديل',
  backLabel: 'عودة',
  start: 'بداية',
  open: 'افتح',
  close: 'أغلق',
  openAction: 'افتح',
  closeAction: 'أغلق',
  stateOpen: 'مفتوح',
  stateClosed: 'مغلق',
  addNode: 'إضافة محطة',
  confirmationTitle: 'التأكيد',
  resetSelection: 'إعادة تعيين الاختيار',
  addSelection: 'إضافة التحديد',
  startAction: 'بدء العمل',
  endAction: 'نهاية العمل',
  save: 'حفظ',
  saved: 'حفظ بنجاح',
  cancel: 'إلغاء',
  send: 'إرسال',
  operationSucc: 'لقد تمت العملية بنجاح',
  ok: 'حسنا',
  yes: 'نعم',
  no: 'لا',
  reset: 'أعاد للوضع السابق',
  finishLabel: 'إنهاء',
  newSearch: 'بحث جديد',
  submit: 'سجل',
  remove: 'حذف',
  addLigneLabel: 'اضف سطر ',
  confirmationbtnLabel: 'تأكيد',
  confirmPassageLabel: 'يرجى تأكيد أن مرور ',
  tookplaceLabel: 'وقعت يوم',
  toPhaseLabel: 'إلى مرحلة ',
  findLabel: 'بحث',
  confirmDelete: 'تأكيد الحذف',
  confirmAction: 'تأكيد الإرسال',
  warningLabel: 'تحذير',
  title: 'العنوان',
  description: 'التفاصيل',
  type: 'النوع',
  fieldRequired: 'إلزامي',
  passwordDntMatch: 'كلمة المرور غير متطابقة',
  emailInvalidLabel: 'البريد الإلكتروني غير صحيح',
  minLength: ' يجب أن يكون الحد الأدنى للطول',
  emailNotValid: 'بريد إلكتروني خاطئ',
  name_label: 'الاسم',
  required: 'هذه الخانة ضرورية',
  mismatch: 'كلمة التأكيد غير متطابقة',
  name_ar: 'الاسم باللغة العربية',
  name_fr: 'الاسم باللغة الفرنسية',
  name_en: 'الاسم باللغة الإنجليزية',
  unity: 'وحدة القيس',
  status: 'الحالة',
  'public': 'عامة',
  'private': 'خاص',
  nonProfit: 'غير ربحية',
  CompanyLabel: 'شركة',
  modelPackName: 'اسم النموذج',
  addModelPack: 'إضافة نموذج',
  editModelPack: 'تغيير نموذج',
  editCategory: 'تغيير الفئة',
  seabexCategoryStation: 'محطة النموذج ',
  seabexModelCategory: 'فئة النموذج  ',
  category: 'الفئة',
  addCategory: 'إضافة فئة',
  addCategoryModel: 'أضف النموذج ',
  editCategoryModel: 'تحرير النموذج  ',
  userCatalogManagement: 'إدارة الكتالوج',
  serielNumber: 'رقم تسلسل',
  categoryName: 'اسم الفئة',
  seabexModelType: 'نوع نموذج ',
  categories: 'الفئات',
  portNameLabel: 'اسم المنفذ',
  portTypeLabel: 'منفذ نوع التسمية ',
  categoryFamily: 'عائلة الفئة',
  companyNameLabel: 'اسم الشركة',
  errorHandler: 'معالجة الأخطاء',
  andLabel: 'و',
  error_text: ' الخطأ',
  errorList: 'قائمة الأخطاء',
  statusCode: 'Code Http',
  errorChecked: 'تم فحص الخطأ',
  errorCorrected: 'تم تصحيح الخطأ',
  checkedLabel: 'التحقق',
  correctedLabel: 'تصحيحه',
  itemManagmentLabel: 'إدارة المنتوجات ',
  item: 'المنتج',
  itemCategory: 'فئة المنتج',
  itemsLabel: 'المنتوجات',
  otherInformations: 'المعلومات الأخرى',
  profile: 'الملف الشخصي  ',
  userPersonnelInfo: 'معلومات المستخدم',
  seeProfile: 'عرض الصفحة الشخصية',
  activesince: 'تاريخ بداية العمل',
  addExpense: 'إضافة حساب',
  farmers: 'المزارعين',
  experts: 'الخبراء',
  viewMore: 'عرض المزيد',
  avatarImportationLabel: 'قم باستيراد صورتك الرمزية ',
  birthDateLabel: 'تاريخ الميلاد (اختياري) ',
  joinedLabel: 'انضم في ',
  lastConnectionLabel: 'آخر اتصال في   ',
  addDataType: 'إضافة نوع البيانات',
  editDataType: 'تعديل نوع البيانات',
  datatype: 'نوع البيانات ',
  dataTypeManagment: 'إدارة نوع البيانات',
  dataTypeNature: 'طبيعة نوع البيانات',
  abbreviationLabel: 'الاختصار',
  systemLabel: 'النظام',
  SingularLabel: 'المفرد',
  pluralLabel: 'جمع',
  defaultUnit: 'الوحدة الأولية',
  unitListsLabel: 'قوائم الوحدات',
  attachDataType: 'إرفاق نوع البيانات بالمعدات',
  dataTypeListOfLabel: 'قائمة أنواع بيانات',
  serviceListLabel: 'الخدمات',
  noDataTypeSelectedYetLabel: 'لم يتم تحديد أنواع البيانات حتى الآن',
  dataTypesDisplayed: 'أنواع البيانات المراد عرضها:',
  managingQuotasLabel: 'إدارة الحصص ',
  quotaLabel: '(m3) حصة نسبية ',
  quota: ' حصة نسبية',
  seasonStartLabel: 'بداية الموسم  ',
  seasonEndLabel: 'نهاية الموسم ',
  addQuota: 'إضافة حصة ',
  editQuota: 'تعديل الحصة ',
  organization: 'منظمة',
  contactOrganization: 'اتصل بالمنظمة',
  organizationName: 'اسم المنظمة',
  newAffiliatedorganization: 'منظمة تابعة جديدة',
  editAffiliatedorganization: 'تعديل منظمة تابعة',
  organizationcity: 'مدينة المنظمة ',
  Organization: 'منظمة',
  formula: 'الصيغة',
  myformulas: 'الصيغة الخاصة بي',
  allFormulas: 'جميع الصيغ',
  seeFormula: 'انظر الصيغة',
  FormulaName: 'اسم الصيغة',
  newFormula: 'صيغة جديدة',
  formules: 'الصيغ',
  formulasList: 'قائمة الصيغ',
  formulaDisplay: 'هذه هي صيغتك',
  owner: 'مالك',
  useFormulaLabel: 'استخدم الصيغة',
  useAlgorithmLabel: 'استخدم الخوارزمية',
  algorithmLabel: 'الخوارزمية',
  addVariableToDataType: 'إضافة متغير لنوع البيانات المحدد',
  nbbeneficiers: 'تحقق من عدد المشتركين',
  AdvicesService: 'خدمة النصائح والتنبيهات',
  newNotification: 'إشعاع جديد',
  notificationType: 'نوع إعلام',
  others: 'الأخرون',
  content: 'محتوى',
  sendingDate: 'تاريخ الارسال',
  lastNotifs: 'أحدث الإخطارات',
  serverSideLabel: 'جانب الخادم',
  tryAgainLabel: 'الرجاء معاودة المحاولة في وقت لاحق',
  checkUrData: 'يرجى التحقق من إدراج البيانات الخاصة بك',
  allStepsCompleted: 'اكتملت جميع الخطوات',
  color: 'أختراللون',
  plantType: 'النوع',
  harvestDate: 'تاريخ الحصاد',
  activityTypes: 'أنواع النشاط',
  expenses: 'النفقات',
  revenues: 'العائدات',
  addRevenue: 'إضافة العائدات',
  quantity: 'الكمية',
  planification: 'تخطيط',
  planning: 'التخطيط',
  Statistics: 'الإحصاء',
  model: 'النموذج',
  Settings: 'الإعدادات',
  userGeoDataManagement: 'إدارة البيانات الجغرافية',
  dashboardLabel: 'لوحة القيادة',
  processingtimeLabel: 'وقت المعالجة',
  CounterLabel: 'عداد',
  allRequestStatusLabel: 'حالة جميع الطلبات',
  requestStatusByDateLabel: 'طلب سجلات الحالة حسب التاريخ',
  requestStatusLogsLabel: 'طلب سجلات الحالة',
  refreshAreaManagement: 'قم بتحديث قائمة المواقع',
  cleanDeletedAreas: 'حذف المواقع المحذوفة بشكل دائم',
  introSeabexTitleGuide: 'مرحبا',
  changeLanguageTitleGuide: 'قم بتغيير لغة عنوان الدليل',
  changeStepsTitleGuide: 'changeStepsTitleGuide',
  stepperTriggerTitleGuide: 'stepperTriggerTitleGuide',
  ariticleTitleGuide: 'ترحيب',
  introSiteTitleGuide: 'Introduction Sites',
  addSiteTitleGuide: 'Ajout d’un Site via ‘+’',
  goToButtonTitleGuide: 'L’ajout de sites ‘Aller à’',
  goToModalTitleGuide: 'La latitude en combinaison avec la longitude',
  drawAreaButtonsTitleGuide: 'Sites ‘ajouter une nouvelle zone’',
  drawPolygonRectangleButtonTitleGuide: 'رسم المواقع على الخريطة',
  addNewAreaModalTitleGuide: 'Les champs pour ajouter le site',
  areaOnMapTitleGuide: 'Le cadre du site',
  areaSoilStructureTitleGuide: 'تكوين التربة',
  nextButtonAddAreaTitleGuide: 'التالي',
  dataSourceTableTitleGuide: 'Type de data sources de data',
  addNewSourceTitleGuide: 'Ajouter des Type de data & sources de data',
  saveBtnAddAreaTitleGuide: 'Enregistrer',
  areasListTitleGuide: 'Notification d’enregistrement d’un nouveau site',
  addAreaUsingFileTitleGuide: 'Ajout d’un nouveau site par l’épingle',
  addParcelSectorTitleGuide: 'Vue de liste des site',
  introCycleTitleGuide: 'مقدمة  الدورات ',
  addCycleBtnTitleGuide: 'إضافة دورة عبر "+"',
  addCycleModalTitleGuide: 'Les champs pour ajouter le cycle',
  cycleListTitleGuide: 'Liste des cycles',
  mapViewBtnTitleGuide: 'Introduction one pager Rapport',
  onePagerMeteoTitleGuide: 'تقرير حالة ألطقس ',
  onePagerWaterNeedTitleGuide: 'Rapport one pager besoin en eau',
  onePagerWaterNeedHierarchyTitleGuide: 'Rapport one pager L\'arborescence du calcul',
  onePagerWaterNeedHistoryTitleGuide: ' تقرير تاريخي عن الاحتياجات المائية',
  introDeployedDevicesTitleGuide: 'مقدمة الأجهزة المنتشرة ',
  addDeployedDeviceBtnTitleGuide: 'إضافة جهاز تم نشره عبر "+"’',
  addDeployedDeviceModalTitleGuide: 'The fields to add the Device',
  addDeployedDeviceModal1TitleGuide: 'Modal Step 1',
  addDeployedDeviceModal2TitleGuide: 'Modal Step 2',
  addDeployedDeviceModal3TitleGuide: 'Modal Step 3',
  realTimeintroTitleGuide: 'realTimeintroTitleGuide',
  realTimeTitleGuide: 'realTimeTitleGuide',
  logsIntroTitleGuide: 'logsIntroTitleGuide',
  logsTitleGuide: 'logsTitleGuide',
  rangeLabel: 'النطاق',
  relayActivationLabel: 'تفعيل التتابع ',
  relayDeactivation: 'تعطيل التتابع ',
  dialog_notNow: 'ليس الان',
  dialog_okAcceptance: 'موافق',
  dialogRecalculateTitle: 'يمكن أن تستغرق عملية إعادة الحساب هذه عدة دقائق. سيتم إخطارك عند اكتمال إعادة الحساب. يرجى الرجوع إلى النتائج الموجودة على الصفحة المخصصة للمخطط المعني بالنقر فوق الزر "معلومات وتوصيات" في سطر المؤامرة المعنية.',
  helpAndSupport: 'مساعدة و دعم',
  watchGuideAgain: 'شاهد الدليل مرة أخرى',
  addAreaNameGuide: 'Site Name',
  addAreaDescriptionGuide: 'Site Description',
  introSeabexGuide: 'Notre tutoriel vous aide à avoir la meilleure expérience sur notre plateforme:\n- La création d’un site/Sous-sites (parcelle/secteur)\n- La création d’un cycle\n- Les appareils déployés\n- Temps réel',
  changeLanguageGuide: 'Veuillez choisir parmi 3 langues à utiliser:\n- Français\n- Anglais\n- Arabe',
  changeStepsGuide: 'changeStepsGuide\nVeuillez passer à l\'étape suivante',
  stepperTriggerGuide: '::stepperTrigger::stepperTriggerGuide\nVeuillez cliquer sur le point d’interrogation, le tutoriel clarifiant une rubrique parmi le menu par exemple Sites sera affiché.',
  articleGuide: 'Veuillez trouver dans l\'accueil, les actualités & les événements qui vous intéressent\n(Exemple: Une formation des agrumes par le Centre Technique des Agrumes (CTA) )',
  introSiteGuide: 'Veuillez commencer par la création des sites/parcelles et secteurs:\nEn effet, un site ( un emplacement dédié aux activités d’agriculture\nUn sous-site/ une parcelle (Portion de terrain de même culture/végétation) et un secteur (Subdivision d\'une parcelle).\n \nVous commencez maintenant par la création d\'un site.',
  addSiteGuide: 'Veuillez commencer par appuyer sur le bouton ‘+’ pour ajouter un nouveau site, vous allez accéder automatiquement à la \'Map View/ vue de la carte\' pour choisir le lieu et dessiner le site sur le World Map.',
  goToButtonGuide: ' Veuillez aller rapidement à n\'importe quel lieu en cliquant sur \'Aller à \' ce bouton vert afin d’avoir une navigation facile et rapide.',
  goToModalGuide: 'Veuillez appuyer sur ‘Aller à’, vous aurez automatiquement un popup d\'un formulaire. Vous avez le choix:\n- Soit de remplir le premier champ avec le nom de pays/ville/ adresse de rue ou lieu connu.\n- Ou bien d\'insérer la latitude en combinaison avec la longitude pour indiquer la position précise d\'un élément sur Terre. Veuillez cliquer sur Aller â coordonnées pour avoir le lieu.',
  drawAreaButtonsGuide: ' Veuillez appuyer sur le bouton ajouter une nouvelle zone afin d\'ajouter un nouveau site.',
  drawPolygonRectangleButtonGuide: '::stopEditing::Si vous voulez arrêter le dessin des limites du site avant le terminer et revenir à l’étape précédente.\n::addAreaPolygon::Pour le polygone , veuillez cliquer une fois sur un point sur les limites du site et finir par cliquer sur le même point du départ.\n::addAreaRectangle::Pour le rectangle, Veuillez cliquer une fois sur la souris, glissez selon la taille du site et finissez par relâcher la souris.\n En appuyant sur le bouton espace du clavier, ces formes seront désactivées et en appuyant une autre fois elles seront réactivées.\n::mapLayers::Vous avez le world Map en plusieurs versions (Satellite, terrain etc...) à travers le bouton des rectangles superposés à droite en haut sur le map.\n::drawAreaButtons::Si vous voulez arrêter le dessin des limites du site avant le terminer et revenir à l’étape précédente.........',
  addNewAreaModalGuide: 'Si vous terminez le dessin des limites du site, un formulaire avec des champs à remplir va apparaître.\n\nVous aurez la possibilité de:\n- Noter le titre du site (champ obligatoire)\n- Noter le champ description (champ non obligatoire)\n\n;;Cas 1:;; Si vous choisissez le type de sol recommandé coché par défaut, vous aurez les champs apparaissant  successivement l’un après le remplissage de l’autre:\n* ‘La famille d\'irrigation’ à remplir avec 3 choix\n* ’Type d’irrigation’ à remplir avec 4 choix\n;;Cas 2:;; Si vous cochez  ‘choisissez mon type de sol’, vous aurez les champs apparaissant  successivement l’un après le remplissage de l’autre:\n* ‘Type de sol’\n* ‘Texture de sol’ à remplir\n* ‘La famille d\'irrigation’ à remplir avec 3 choix\n* ‘Type d’irrigation’ à remplir avec 4 choix',
  areaOnMapGuide: '- Le site créé avec un cadre bleu sur la carte selon les limites dessiné aux étapes précédentes.',
  areaSoilStructureGuide: '- Vous aurez une composition détaillée du sol du site.\n* À gauche vous trouvez les différentes profondeurs du sol\n* Chaque ligne représente le pourcentage de chacun de ces éléments dans votre sol à ce niveau.\n*À droite vous trouvez les composants essentiels des sols : l\'argile, le limon et le sable.\n*En bas vous trouvez le pourcentage de chacun de ces éléments dans votre sol.',
  nextButtonAddAreaGuide: 'Une fois les champs sont remplis, veuillez avancer dans la configuration du site en appuyant sur le bouton SUIVANT',
  dataSourceTableGuide: 'Vous pouvez aller au niveau de choisir parmi les paramètres ‘Type de data’ ou en ajoutant les vôtres, obtenus en téléchargeant auparavant des fichiers ‘sources de data’ ou bien en choisissant celles par défaut comme’ Seabex Default’.',
  addNewSourceGuide: '- Pour ajouter un nouveau data type au niveau des paramètres ‘Type de data’.\n- Vous pouvez ajouter vos propres types de données en cliquant sur le bouton +.\n- Par défaut, ce dernier type de données va être ajouté à la fin de la liste des autres données.\n- De plus ce dernier va avoir par défaut “SEABEX DEFAULT” comme source mais vous pouvez en choisir une autre.',
  saveBtnAddAreaGuide: 'Une fois les champs sont remplis, vous pouvez finir la configuration du site en appuyant sur le bouton ENREGISTRER',
  areasListGuide: 'Une fois les étapes de la création d’un nouveau site sont terminés,\n::snackBar::Une notification va apparaître en bas à gauche de l’écran:\n;;- Cas 1:;; Si le site n’est pas créé à cause d’une erreur, la notification va être rouge affichant ‘erreur’\n;;- Cas 2:;; Si le site est créé, la notification va être verte affichant ‘Ajouté avec succès’\n;;-Cas 3:;; ......\n\nD\'autre, vous pouvez voir une liste de tous les sites créés des actions sur chaque site (comme modifier ou supprimer).\n',
  addAreaUsingFileGuide: 'Vous avez une autre méthode pour ajouter un nouveau site:  Veuillez appuyer sur l’épingle pour télécharger un fichier KML, JSON ou GeoJSon contenant les coordonnées du site.\nEn téléchargeant le fichier, vous serez automatiquement dans l’étape du formulaire de la création d’un site les mêmes étapes.',
  addParcelSectorGuide: 'Ajout d’une sous-partie (parcelle d’un site ou un secteur d’une parcelle):\n::mapButton:: En appuyant sur ce bouton, vous allez accéder automatiquement au mapview et avoir la possibilité d’ajouter une sous-partie (parcelle d’un site ou un secteur d’une parcelle).\nDans le map, en faisant d’un double clic dans un site, vous pouvez créer une sous-partie. (parcelle d’un site ou un secteur d’une parcelle).........\n::arrowSite:: En appuyant sur la flèche à gauche des noms de sites, une liste des sous-sites/ parcelles s’affiche en dessous.',
  introCycleGuide: 'Vous commencez par la création d’un cycle afin d’avoir plusieurs informations utiles à propos de vos cycles comme le besoin en eau.\nEn effet, le type de production peut être classifié suivant différents critères:\n;;la portée de l’activité agricole:;; à des fins de consommation ou industrielles, ces dernières peuvent avoir à leur tour une finalité alimentaire (arachide) ou de transformation industrielle (matière première: coton)\n;;le type de culture:;;Pérenne (arbres fruitiers) ou annuelle (maïs)\n;;l’objectif de la production animale:;;Consommation alimentaire ou valorisation des sous-produits, etc.',
  addCycleBtnGuide: 'Veuillez commencer par appuyer sur le bouton ‘+’ pour ajouter un nouveau cycle et vous aurez un formulaire à remplir contenant plusieurs champs.',
  addCycleModalGuide: 'Les champs à remplir pour déclarer le cycle:\n- Site: choisir le site pour lequel vous avez ce cycle\n- Famille de culture: Il ya 4 familles cultures fourragères, arboricultures, cultures maraîchères,  céréales \nEn choisissant une parmi ces familles, un champs des sous familles apparaît:\n- Culture: dépend de la famille de culture choisisse\n- La date de début du cycle: Depuis combien du temps ces cultures sont plantés\n::cycleDate:: \n- Après l\'agriculteur va choisir:\n- (a) Dans le cas: Utiliser le cycle de production recommandé  les champs suivantes apparaissent:\nCycle de Production*\nProfondeur d\'enracinement (mètre)*\nNombre de jours d\'irrigation (champs non-obligatoire)\nQuantité d\'eau dans le sol  (champs non-obligatoire)\nCapacité au champ*\nPoint de flétrissement*\n::recommandedProductionCycle:: \n \n- (b) Dans le cas: Personnalisez votre propre cycle de production les champs obligatoires suivantes apparaissent:\nPhase initiale*\nPhase de développement*\nPhase mi-saison*\nPhase arrière-saison*\nProfondeur d\'enracinement (mètre)*\nNombre de jours d\'irrigation\nQuantité d\'eau dans le sol\nCapacité au champ*\nPoint de flétrissement*\n::productionCycle:: Le calcul en besoin en eau et la préparation d’un rapport ‘one pager’ sera calculé automatiquement et vous attendez jusqu’à le loading se terminera\n- Cas 1: Si le cycle n’est pas créé à cause d’une erreur, la notification va être rouge affichant ‘erreur’\n \n::cultureCycle:: \n- Cas 2: Si le cycle est créé, la notification va être verte affichant ‘Ajouté avec succès’\n- Cas 3: Si le cycle existe déjà pour ce site (sera double),lLa notification va être bleu. \n::existCycleNotif:: \n',
  cycleListGuide: 'Une fois les champs remplis et les étapes de la création d’un nouveau cycle sont terminés, vous vous trouvez automatiquement la liste de vos cycles.\nLes colonnes contenant:\n- Les noms des sites\n- Noms de cultures\n- Date de début du cycle (Lorsqu’ils sont plantés)\n- leurs descriptions insérées par vous précédemment dans le formulaire de la création du site.\n* Vous obtenez les informations comme valeur ajouté du SEABEX :\n-Nombre de jours depuis l’implantation\n-Fin de cycle: Peut être terminé selon la durée recommandée automatiquement ou bien manuellement en cliquant sur le bouton\n-La phase dans laquelle se trouve votre culture:  phase initiale, phase de développement, phase mi-saison, phase arrière saison.\n- En appuyant sur la flèche à gauche des noms des cycles, la phase dans laquelle se trouve votre culture  + la date de début et de fin recommandée pour votre cycle s’affiche en dessous.',
  mapViewBtnGuide: 'Veuillez appuyer sur le bouton à droite,  vous allez accéder automatiquement au mapview et avoir “un one pager”, un rapport contenant:\n- La structure du sol dans la zone sélectionnée\n- Les données météorologiques:\n- Détails du cycle\n- l\'historique des besoins en eaux avec des courbes\n- les détails de l\'irrigation\n- la quantité d\'eau à irriguer\n- l\'arborescence du calcul du besoin en eau',
  onePagerMeteoGuide: 'Nom de culture : (exemple: Haricots vert) et les paramètres: Quantité à irriguer et ses inputs par exemple Evapotranspiration Réelle',
  onePagerWaterNeedGuide: 'onePagerWaterNeedGuide 24',
  onePagerWaterNeedHierarchyGuide: 'Veuillez  trouver l\'arborescence du calcul du besoin en eau,chaque ligne représente les inputs nécessaires pour le paramètre dans la ligne en dessus jusqu’à avoir la quantité d’eau à irrigué.\nToutes les données sont calculées pour 24',
  onePagerWaterNeedHistoryGuide: 'Veuillez choisir la période pour laquelle vous voulez visualiser la quantité d’eau irriguée et d’autres données.\nVeuillez appuyer sur\nVous allez avoir des courbes qui représentent les données suivantes qui apparaissent en cochant les cases à côté\nLa Réserve d\'eau ajustée\nIrrigation suggérée\nÉvapotranspiration de référence\nEvapotranspiration Réelle\nPrécipitations\nIrrigationQuantité à irriguer\nCapacité au champ\nPoint de flétrissement\nQuantité d\'eau dans le sol\nVeuillez trouver les données enregistrées chaque 24 h dans ce tableau comme historique de référence pour vous.',
  introDeployedDevicesGuide: 'تبدأ بإنشاء جهاز ',
  addDeployedDeviceBtnGuide: 'يرجى البدء بالضغط على زر  لإضافة جهاز جديد وسيكون لديك نموذج لملئه بالعديد من الحقول.',
  addDeployedDeviceModalGuide: 'The fields to be filled in to declare the Device:\n- Site: choose the site for which you have this Device\n- stationName : the Device name \n device Category: There are 9 Category like Gateway , Action , Acquisition \nBy choosing one among these Categories, a field of the sub-Categories appears:\n-Device Family: depends on the Category  chosen\n- Device Model : depends on the Device family  chosen\n-Identifier : the Device identifier \n',
  addDeployedDeviceModal1Guide: 'step 1 explain fields contens',
  addDeployedDeviceModal2Guide: 'step 2 explain how to draw and change location using gif image including some text',
  addDeployedDeviceModal3Guide: 'step 3 explain in use case if gateway there is no step 3 and the other use cases explain the fields to fill',
  realTimeintroGuide: 'Vous pouvez trouver la liste des appareils déployès ici.',
  realTimeGuide: '::realTimeStatus:: Vous pouvez connaît si vos etes connecter aux appareils ou pas.\n \n \n::realTimeTable:: \n \n \nVous trouvez ici la liste des appareils déployés, avec quelques actions sur les appareils (OUVERTURE, FERMETURE).\n Si vous êtes dans le cas d\'une erreur, ou bien d\'un changement d\'état, Vous serez notifier par la barre de notification.',
  logsIntroGuide: 'Vous pouvez trouver l\'historique de toutes les données capturées ici.',
  logsGuide: 'Vous pouvez chercher et lister les journaux à travers le choix de site ou bien le choix d\'un appareil déployé.\n::logsStatics:: \n Ce diagramme liste les valeurs capturées à travers le temps.\n::logsTable:: \n Vous trouvez toutes les informations capturées dans ce tableau trié avec le temps de capture.\n::downloadXSL:: Vous pouvez même télécharger un fichier de format XSL contenant toutes les données capturent en cliquant sur ce bouton',
  ordersMenu: 'مشترياتي واشتراكاتي',
  orderDate: 'تاريخ',
  orderTotal: 'المجموع الخالص',
  orderDetail: 'تفاصيل أمر الشراء',
  ordersList: 'قائمة أوامر الشراء',
  nowConnected: 'أنت الآن متصل بالإشعارات الفورية!',
  nowDisconnected: 'أنت الآن غير متصل بالإشعارات الفورية!',
  productionCycleCalculationNotification: 'تم إعادة حساب دورة إنتاج الثقافة %culture_name% على %area_name%',
  mySubscribedServices:'الخدمات التي اشتركت بها',

  // Payment Manager
  paymentConfirming: "جاري تأكيد الدفع ...",
  paymentSuccessful: "تم أخذ دفعتك في الاعتبار ، ستتلقى تأكيدًا عبر البريد الإلكتروني.",

  // extra

  impersonatingUser: "أنت تنتحل حساب مستخدم ",
  sessionEndsIn: "تنتهي الجلسة في:",
  leaveImpersonation: "إنهاء الجلسة",
  solMaximumDepth: 'أقصى عمق للتربة قابل للاستغلال',
  unknownDepth: "لا أعرف عمقي الافتراضي",
  compactionRate: "معدل الانضغاط بال٪",
  greenFruits: "الفواكه الخضراء",
  changeStartDate: "لتغيير تاريخ بداية مرحلة ظاهرة، انقر عليها على شريط التقدم.",
  totalCumulativePrecipitation: "إجمالي وتراكمي للهطول بالشهور",
  totalCumulativePrecipitationIrrigation: "إجمالي وتراكمي هطول الأمطار والري شهريا",
  demandeAssistance: 'طلب مساعدة',
  videosTutoriaux: 'فيديوهات تعليمية',
  rainfallPerMonth: 'هطول الأمطار في الشهر',
  irrigationPerMonth: "الري لكل شهر",
  dontKnow: 'أنا لا أعرفها',
  numberDripperPerFoot: "عدد القطارات لكل قدم",
  flowRatePerDripper: "معدل التدفق لكل قطارة",
  speed: 'السرعة',
  radius: 'شعاع',
  partner: 'الشريك',
  permissions: 'صلاحيات',
  unit: 'وحدة',
  yield: 'عائد',
  quintals: 'كوينتال للهكتار (q/ha)',
  tons: 'أطنان للهكتار (t/ha)',
  kilograms: 'كيلوجرام للهكتار (kg/ha)',
  renewalMsg: '🌟 قم بتجديد اشتراكك الآن ووفّر 10% ✨! 💼🔒 عرض محدود حتى نهاية يناير 2024',
  discountForAllMsg: '🌟 اشترك الآن ووفر 10%! 💼🔒 العرض محدود حتى %validity%',

  activeSubscriptionExpiration: " الاشتراك النشط تنتهي صلاحيته في",
  save_order_btn : 'إرسال الطلب وطلب المراجعة',
  laterProcessingMessage : "يمكنك إرسال طلبك وطلب أن نرسل لك عرض أسعار. سيسمح لك ذلك بالدفع عن طريق الشيكات أو التحويل والحصول على التحقق السريع. لمزيد من المعلومات لا تترددوا في الاتصال بنا عبر الهاتف",
  laterProcessingMessageMailPart : "أو عن طريق البريد الإلكتروني على",
  postal_code: 'الرمز البريدي',

  partner_activate:'تنشيط',
  partner_configure:'تكوين',
  partner_disable:'تعطيل',



  // Alert Messages
  'Updates are in progress, sorry if you encounter any instability.': "التحديثات جارية ، آسف إذا واجهت أي عدم استقرار."
}